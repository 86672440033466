/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ochrana osobních údajů"}>
        <SiteHeader />

        <Column className="pb--50 pt--30" name={"Obsah"} style={{"backgroundColor":"rgba(206,178,162,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"gej62jfsloh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Zásady ochrany osobních údajů"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"vwnbojonu3"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"I. Základní ustanovení\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"1. Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: „GDPR”) je Klára Ševerová, IČ 04285077, se sídlem Havlovice 297, Úpice 542 32, Česká republika (dále jen: „správce“).\n<br>2. Kontaktní údaje správce jsou<br>adresa: Mánesova 584, Hradec Králové 500 02<br>email: klara@dogmom.cz<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"3. Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.\n<br>4. Správce nejmenoval pověřence pro ochranu osobních údajů."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"q3prp9cr9u"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"II. Zdroje a kategorie zpracovávaných osobních údajů"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"1. Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na základě plnění Vaší objednávky."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"2. Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro plnění smlouvy."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"s6o79wkxol"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"III. Zákonný důvod a účel zpracování osobních údajů"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"1. Zákonným důvodem zpracování osobních údajů je\n<br>- plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR,&nbsp; <br>- oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. f) GDPR,\n<br>- Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti v případě, že nedošlo k objednávce zboží nebo služby."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"2. Účelem zpracování osobních údajů je\n<br>- vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit,\n<br>- zasílání obchodních sdělení a činění dalších marketingových aktivit.\n<br>3. Ze strany správce nedochází / dochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 GDPR. S takovým zpracováním jste poskytl/a svůj výslovný souhlas."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"9uwuzerr0jg"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"IV. Doba uchovávání údajů"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Správce uchovává osobní údaje\n<br>- po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 15 let od ukončení smluvního vztahu).\n<br>- po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely marketingu, nejdéle 5 let, jsou-li osobní údaje zpracovávány na základě souhlasu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"2. Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"tymwsxqu8r8"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"V. Příjemci osobních údajů (subdodavatelé správce)"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"1. Příjemci osobních údajů jsou osoby\n<br>- podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,\n<br>- podílející se na zajištění provozu služeb,\n<br>- zajišťující marketingové služby."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"2. Správce nemá / má v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci. Příjemci osobních údajů ve třetích zemích jsou poskytovatelé mailingových služeb / cloudových služeb."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"j5ghohxrvdm"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"VI. Vaše práva"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"1. Za podmínek stanovených v GDPR máte\n<br>- právo na přístup ke svým osobním údajům dle čl. 15 GDPR,\n<br>- právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR.\n<br>- právo na výmaz osobních údajů dle čl. 17 GDPR.\n<br>- právo vznést námitku proti zpracování dle čl. 21 GDPR a\n<br>- právo na přenositelnost údajů dle čl. 20 GDPR.\n<br>- právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce uvedený v čl. III těchto podmínek."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"2. Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"hgfyou4lpz4"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"VII. Podmínky zabezpečení osobních údajů"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"1. Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.\n<br>2. Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"3. Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"1gwizzqteb6"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--24" content={"VIII. Závěrečná ustanovení"}>
              </Title>

              <Text className="text-box fs--13" content={"Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"1. S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"2. Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách, případně Vám zašle novou verzi těchto podmínek na e-mailovou adresu, kterou jste správci poskytl/a."}>
              </Text>

              <Text className="text-box" content={"Tyto podmínky nabývají účinnosti dnem 24. 8. 2022."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Image src={"https://cdn.swbpg.com/o/26402/df60f83ccf2f4be8867ec8bcdeed2086.svg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"info@dogmom.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}